@import "src/styles/variables";

.footerContainer {
  opacity: 0;
  animation: fadeIn 400ms ease-out forwards;
  position: relative;
  z-index: 3;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--footerBackground);
}

.content {
  // border-top: 1px solid #efecec;
  border-top: none;
  padding-top: 36px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 36px;
  max-width: 1008px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: var(--footerBackground);
    height: 1px;
    left: 4px;
    right: 4px;
    bottom: 0px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 16px - 16px);
  padding-left: 16px;
  padding-right: 16px;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.colRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 34px;
  width: 200px;
}

.text {
  color: var(--text-color);
  font-size: 15px;
  line-height: 18px;
  font-family: AppleSDGothicNeo-Medium;
}

.scammerText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  width: 485px;
  color: var(--text-color);
  font-size: 15px;
  line-height: 18px;
  font-family: AppleSDGothicNeo-Medium;
  margin-top: 15px;
}
.ageRestrict {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  width: 485px;
  svg {
    min-width: 35px;
  }
}

.logoIcon {
  width: 44px;
  height: 34px;
}

.logoNameIcon {
  height: 30px;
  width: 180px;
}

.copyRightContainer {
  padding-top: 32px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.notes {
  color: var(--text-color);
  font-size: 13px;
  line-height: 17px;
  font-family: AppleSDGothicNeo-Regular;
  white-space: pre-wrap;
  text-align: center;
}

.copyRight {
  color: var(--text-color);
  font-size: 15px;
  line-height: 18px;
  font-family: AppleSDGothicNeo-Medium;
}

@media only screen and (min-width: $screen-xl) {
  .content {
    padding-top: 37px;
    max-width: 1080px;
    border-top: solid 1px #253d53;
  }

  .row {
    width: calc(100% - 22px - 14px);
    padding-left: 22px;
    padding-right: 14px;
  }
}

@media only screen and (min-width: $screen-2xl) {
  .row {
    width: calc(100% - 24px - 12px);
    padding-left: 24px;
    padding-right: 12px;
  }
}

@media only screen and (min-width: $screen-3xl) {
  .content {
    max-width: 1140px;
  }
  .row {
    width: calc(100% - 18px);
    padding-left: 18px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: $screen-sm) {
  .logoNameIcon {
    height: 23px;
    width: 128px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.freeGamesBackground {
  // background: linear-gradient(rgba(255, 255, 255, 0.001), rgba(255, 255, 255, 0.001)),
  //   url("/assets/img/footer-background.png") no-repeat !important;
  background-size: cover !important;
}
