.wrapperUsername {
  position: relative;
}

.inputBordered {
  position: relative;
  padding: 0px;
  margin: 0px 0px 4px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  transition: all 0.4s ease-in-out;
  /* Reveal Message */
  background: var(--loginInputFieldBG);
  z-index: 1;
  height: 56px;
}
.inputBorderedError {
  box-shadow: inset 0 0 0 2px var(--inputFieldErrorBorderColor) !important;
  transition: all 0.4s ease-in-out;
}
.inputBorderedActive {
  box-shadow: inset 0 0 0 2px #00acee !important;
  transition: all 0.4s ease-in-out;
}
.inputBorderedValid {
  box-shadow: inset 0 0 0 1px #00acee;
  transition: all 0.4s ease-in-out;
}

.formInput {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 54px;
  color: var(--loginInputColor);
}
.formInput::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 1px;
}

.formInputIOS {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 57px;
  color: var(--loginInputColor);
}

.formInputIOS:-webkit-autofill,
.formInput:-webkit-autofill {
  -webkit-text-fill-color: var(--loginInputColor);
  -moz-text-fill-color: var(--loginInputColor);
}

.formInputIOS::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput::-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput:-ms-input-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.formInput:-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.labelContainer {
  position: absolute;
  display: flex;
  top: -8px;
  left: 11px;
  background-color: transparent;
  box-sizing: content-box;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1;
  cursor: default;
  margin: 0px;
  padding: 0px;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: rgb(82, 82, 82);
  overflow: hidden;
}
.label {
  white-space: nowrap;
  background: var(--loginInputLableBG);
  padding: 0px 5px;
  border-radius: 5px;
  color: var(--loginInputColor);
}
.labelInfo {
  font-family: AppleSDGothicNeo-SemiBold;
  white-space: nowrap;
  overflow: hidden;
  background: var(--loginInputLableBG);
  max-width: 0px;
  padding-right: 0px;
  transition: all 1s ease-in-out 0s;
  color: var(--loginInputColor);
}
.labelInfoDefault {
  color: var(--loginInputColor);
  max-width: 0px;
  transition: all 1.5s ease-in-out;
}
.labelInfoActive {
  color: var(--loginInputColor);
  max-width: 100%;
  padding-right: 5px;
  transition: all 1s ease-in-out;
}

.labelInfoError {
  color: var(--inputFieldErrorBorderColor);
  max-width: 100%;
  padding-right: 5px;
  transition: all 1s ease-in-out;
}
.usernameMessage {
  position: relative;
  display: flex;
  opacity: 0;
  height: 28px;
  transition: all 0.3s ease-out;
  padding-left: 4px;
}
.usernameMessageDefault {
  opacity: 1;
  height: 28px !important;
}
.usernameMessageActive {
  opacity: 1;
  height: 28px;
}
.usernameMessageError {
  opacity: 1;
  height: 33px;
}
.usernameMessageValid {
  opacity: 1;
  height: 33px;
}

.usernameHelper {
  display: flex;
  padding-top: 1px;
  position: absolute;
  line-height: 15px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  opacity: 1;
  /* height: 0; */
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 15px;
}
.usernameHelperActive {
  height: 30px;
}
.usernameHelperError {
  height: 30px;
  transition: all 1.5s ease-out;
}

.usernameHelperValid {
  height: 30px;
  transition: all 1.5s ease-out;
}

.usernameHelperText {
  padding-left: 4px;
  margin-top: -1px;
}
.usernameHelperTextValid {
  margin-top: -1px;
  color: #00acee;
}
.usernameHelperTextError {
  margin-top: -1px;
  padding-left: 4px;
  color: var(--inputFieldErrorColor);
}

.parenStyle {
  color: rgb(82, 82, 82);
}

@media screen and (max-width: 673px) {
  .label:not(.loginLable) {
    background: var(--mobileRegisterInputLableBG1);
  }
  .labelInfo {
    background: var(--mobileRegisterInputLableBG1);
  }
  .loginInfoLable {
    background: var(--loginInputLableBG);
  }
}
