.container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  opacity: 0;
  transition: opacity 0.6s ease-in;
  &.show {
    opacity: 1;
  }
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.title {
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 16px;
  line-height: 20px;
  color: var(--loginInputColor);
}

.check {
  transform: scale(0.5) rotate(-360deg);
  opacity: 0;
  transition: all 0.6s ease-in-out;
  &.show {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

.description {
  font-family: AppleSDGothicNeo-Regular;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: var(--loginPlaceholderColor);

  .bold {
    font-family: AppleSDGothicNeo-Bold;
    color: var(--loginInputColor);
  }

  .contentBlue {
    color: #00acee;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: calc(100% - 48px);
  padding-left: 24px;
  padding-right: 24px;
}

.button {
  height: 52px;
  width: calc(100% - 30px);
}

.contentLinks {
  padding: 0px;
  padding-bottom: 40px;
  text-align: center;
  background: var(--loginInputFieldBG);
  display: flex;
  justify-content: center;
  column-gap: 48px;
}

.links {
  font-family: AppleSDGothicNeo-Regular;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: normal;
  text-align: left;
  color: var(--loginInputColor);
  text-decoration: underline;
  cursor: pointer;
  transition: 0.2s color ease-in-out;

  &:hover {
    color: #1fbfff;
  }
}
