.body {
  transition: min-height 0.4s ease-in-out;
  &.login {
    min-height: 417px;
  }

  &.findId {
    min-height: 340px;
  }

  &.findPassword {
    min-height: clamp(1px, calc(100vh - 168px), 628px);
  }

  &.findIdSuccess {
    min-height: 279px;
  }
  &.findPasswordSuccess {
    min-height: 305px;
  }
}
