.container {
  transition: 200ms linear;
  background-color: white;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 1px 4px #00000008;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 14px 16px 5px;
  height: 60px;

  font-family: AppleSDGothicNeo-Bold;

  cursor: pointer;

  svg {
    path {
      transition: 200ms linear;
    }
  }
}

.active {
  border: solid 1px #0ca9ef;
  background-color: #00acee;
  color: white;
}

.new {
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 2;
}
