@import "src/styles/variables";

.gameTabsContainer {
  background-color: #fcfcfc;
  animation: fadeIn 300ms ease-in-out forwards;
  @media screen and (min-width: $screen-lg) {
    width: 100%;
  }
}

.greyhounds {
  min-width: 94px;
  @media screen and (max-width: 480px) {
    flex: 1;
  }
}

.american_football {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 61px;

  @media screen and (max-width: 480px) {
    flex: 1;
  }
}

.horseracing {
  min-width: 68px;
  @media screen and (max-width: 480px) {
    flex: 1;
  }
}

.soccer {
  min-width: 56px;
  @media screen and (max-width: 480px) {
    flex: 1;
  }
}

.basketball {
  min-width: 56px;
  @media screen and (max-width: 480px) {
    flex: 1;
  }
}
