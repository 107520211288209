.swiperContainer {
  max-width: 1090px;
  margin: 8px auto 8px;
  position: relative;
  padding: 0 8px;
}

.carouselItem {
  max-width: max-content;
  margin: 0 auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.carouselItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 350px;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.arrow.arrow_left {
  left: 0;
}
.arrow.arrow_right {
  right: 0;
}

.swiper_button_disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  .arrow.arrow_left {
    left: 20px;
  }
  .arrow.arrow_right {
    right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .arrow.arrow_left {
    left: 30px;
  }
  .arrow.arrow_right {
    right: 30px;
  }
}

@media screen and (max-width: 480px) {
  .arrow.arrow_left {
    left: 10px;
  }
  .arrow.arrow_right {
    right: 10px;
  }
}

.skeletonContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 39vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.skeletonContainer2 {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 39vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.skeleton {
  height: 100%;
  width: 99%;
  margin: 10px 0;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 28px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 75%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media (max-width: 450px) {
  .skeletonContainer,
  .skeletonContainer2 {
    max-height: 44vw;
  }
}
