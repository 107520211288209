.container {
  // border: solid 1px #e6e6e6;

  display: flex;
  flex-direction: column;
}

.header {
  background-color: #00acee;
  height: 40px;
  width: calc(100% + 1px);
  box-sizing: border-box;
  position: relative;
  top: -1px;
  left: -0.5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: AppleSDGothicNeo-Bold;
  font-size: 14px;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 13px 16px 10px 24px;
}

.icon {
  svg {
    width: 16px;
    height: 16px;
  }
}

.gameLabel {
  margin-left: 8px;
  color: #00acee;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 13px;
}

.round {
  color: #777777;
  font-size: 13px;
  font-family: AppleSDGothicNeo-SemiBold;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}

.leagueLabel {
  margin-left: 8px;
  color: #777777;
  font-size: 13px;
  font-family: AppleSDGothicNeo-SemiBold;
}

.firstRow {
  display: flex;
  align-items: center;
  height: 16px;
  margin-bottom: 10px;
}

.teamRow {
  display: flex;
  height: 24px;
  align-items: center;
  margin-bottom: 10px;
  img {
    height: 16px;
  }
}

.teams {
  margin: 0 10px;
  display: flex;
}

.team {
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 13px;
  color: #00acee;

  display: flex;
  align-items: center;
}

.home {
  @extend .team;
}

.away {
  @extend .team;
}

.vs {
  margin: 0 8px;
  color: var(--tabsColor);
  font-size: 13px;
  font-family: AppleSDGothicNeo-Bold;
}

.marketsRow {
  display: flex;
  align-items: start;
  min-height: 24px;

  .market {
    color: var(--tabsColor);
    font-family: AppleSDGothicNeo-SemiBold;
    font-size: 14px;

    max-width: calc(100% - 80px);

    // max-width: 90px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }

  .oddsName {
    color: #00acee;
    font-family: AppleSDGothicNeo-SemiBold;
    font-size: 14px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    text-transform: capitalize;
  }
}

.newLine {
  flex-direction: column;

  .oddsName {
    margin-left: 0;
    margin-top: 8px;
    margin-bottom: 16px;
    row-gap: 5px;
  }
}

.circle {
  width: 8px;
  height: 8px;
  background-color: #00acee;
  border-radius: 8px;

  position: absolute;
  top: 22px;
  left: 14px;
  z-index: 3;
}

.line {
  position: absolute;
  width: 1px;
  background-color: #e3e3e3;
  top: 27px;
  bottom: 26px;
  left: 17.5px;

  &.forecast {
    bottom: 31.5px;
  }
}

.odds {
  padding: 4px 7.5px;
  color: #00acee;
  border: solid 1px #00acee;
  font-family: AppleSDGothicNeo-Bold;
  font-size: 13px;
  margin-left: auto;
  border-radius: 6px;
  display: flex;
  justify-items: center;
  align-items: center;

  .oddsText {
    line-height: 15px;
    height: 13px;
  }

  @media screen and (max-width: 508px) {
    right: 0px;
    top: 59px;
  }
}

.bet {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 4px;

  .label {
    font-size: 13px;
    color: var(--tabsColor);
    font-family: AppleSDGothicNeo-SemiBold;
  }

  .amount {
    margin-left: 12px;
    font-size: 13px;
    vertical-align: middle;
    font-family: AppleSDGothicNeo-SemiBold;
    color: #00acee;
    border-bottom: #fd8f1f 1px solid;
    width: 120px;
    text-align: end;
    padding-right: 8px;
    box-sizing: border-box;
    line-height: 16px;
  }

  .estimatedWinAmount {
    margin-left: 12px;
    font-size: 13px;
    color: var(--tabsColor);
    font-family: AppleSDGothicNeo-SemiBold;
  }
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: relative;
  border: solid 1px #e6e6e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  position: relative;
  padding: 20px 16px 20px 32px;
}

.dateTime {
  color: #fff;
  font-size: 13px;
  font-family: AppleSDGothicNeo-SemiBold;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
