.container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 54px;
}

.logoContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.logoIcon {
  height: 25px;
  width: 32px;
}
