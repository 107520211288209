@import "src/styles/variables";

.container {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 660px;
  justify-content: center;

  // &.hasAuth {
  // justify-content: flex-start;
  // }
}

.navItem {
  @media screen and (min-width: $screen-lg) {
    button::after {
      transition: 0.2s ease-in-out all;
      content: " ";
      left: 0px;
      bottom: 0px;
      position: absolute;
      margin-left: 4px;
      margin-right: 4px;
      width: calc(100% - 8px);
      height: 6px;
      background: #008fc700;
      border-radius: 9px;
    }

    &.active {
      button::after {
        background: var(--buttonActivebottomLine);
      }
      button {
        color: var(--headerActiveColor);
      }
      button:hover {
        background-color: var(--background);
      }
    }
  }
}
