@import "src/styles/variables";

.container {
  position: relative;
  // width: 313px;
  width: 80%;
  // height: calc(100vh - 64px);
  display: flex;
  justify-content: center;

  @media screen and (min-height: 500px) {
    // height: 436px;
  }

  @media screen and (min-width: $screen-sm) and (max-width: 1150px) and (min-height: 600px) {
    width: 300px;
  }
  @media screen and (min-width: $screen-sm) and (max-width: 1150px) and (min-height: 800px) {
    width: 554px;
  }
  @media screen and (min-width: 1150px) and (min-height: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 75px;
  }

  @media screen and (min-width: 1150px) and (min-height: 800px) {
    width: 1000px;
    display: flex;
    justify-content: center;
    gap: 75px;
  }
}

.closeContainer {
  position: relative;
  right: -20px;
  display: flex;
  width: 100%;
  cursor: default;
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  // margin-top: -4px;
  width: 56px;
  height: 56px;
  background: none;
  border: none;
  outline: none;
  padding: 20px;
  svg {
    height: 100%;
    width: 100%;

    path {
      transition: fill 0.2s ease-in-out;
      fill: #ffffff;
    }
  }
}

.redirectBtn {
  display: none;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  width: calc(100% - 48px);
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 5px 24px;
  margin-top: 8px;
  border-radius: 8px;
  box-shadow: #00000029 0px 3px 6px;
  span {
    transition: color 0.2s ease-in-out;
    font-family: AppleSDGothicNeo-Medium;
    color: #525252;
    font-size: 15px;
    line-height: 22px;
  }
}
.bannerImage {
  // height: 100%;
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  @media screen and (min-width: $screen-sm) and (min-width: 1150px) and (min-height: 600px) {
    width: 30%;
  }
  @media screen and (min-width: $screen-sm) and (max-width: 1150px) and (min-height: 800px) {
    width: 80%;
  }
  @media screen and (min-width: 1150px) and (min-height: 800px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) and (min-height: 1050px) {
    width: 50%;
  }
}
@media (hover: hover) {
  .redirectBtn {
    &:hover {
      background-color: #1fbfff;
      span {
        color: #fff;
      }
    }

    &:active {
      background-color: #00acee;
      span {
        color: #fff;
      }
    }
  }

  .closeBtn {
    &:hover {
      path {
        fill: #1fbfff;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 674px) {
  .closeBtn {
    display: flex;
  }

  .redirectBtn {
    display: flex;
  }
}
