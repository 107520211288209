.homeSwiperV3 .swiper-wrapper {
  padding-bottom: 20px;
}

.homeSwiperV3 .swiper-pagination-bullets {
  bottom: 0px !important;
}

.homeSwiperV3 .swiper-pagination-bullet {
  background: #636363;
  opacity: 1;
}

.homeSwiperV3 .swiper-pagination-bullet-active {
  background: var(--bulletBackground);
}

.homeSwiperV3 .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}
