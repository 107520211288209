@import "src/styles/variables";

.homeBGLogo {
  display: flex;
  transition: 0.2s ease-in-out background-color, 0.4s ease-out width;
  width: 123px;
  background-color: #00abee00;
  align-items: center;
  // &.walletOpen {
  //   width: calc(100vw - 125px);
  // }

  img,
  svg {
    width: 123px;
  }

  &:hover {
    // background-color: #0184bf;
    path {
      fill: var(--headerHoverColor);
      transition: 0.2s ease-in-out all;
    }
  }

  &.logout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.homeBGLogo:active {
  background-color: transparent;
}

@media screen and (min-width: $screen-sm) {
  .homeBGLogo {
    padding-left: 24px;
    padding-right: 24px;

    &.walletOpen {
      width: 123px;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .homeBGLogo {
    &.logout {
      position: unset;
      transform: unset;
      top: unset;
      bottom: unset;
      left: unset;
    }
  }
}

@media screen and (min-width: $screen-xl) {
  .homeBGLogo:hover {
    cursor: pointer;
    // background-color: #0184bf;
  }

  // .homeBGLogo:active {
  //   background-color: #06aaeb;
  // }
}

@media screen and (max-width: $screen-sm) {
  .slideHomeBGLogo {
    width: calc(100vw - 125px);
  }
}
