@import "src/styles/variables";

.container {
  overflow-y: hidden;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  padding: 8px;
  box-sizing: border-box;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  column-gap: 6px;

  @media screen and (min-width: 674px) {
    width: calc(100% - 4px);
    min-width: calc(100% - 4px);
  }

  @media screen and (min-width: $screen-lg) {
    width: 794px;
    min-width: 794px;
    justify-content: center;
    padding-bottom: 2px;
  }
  @media screen and (min-width: $screen-xl) {
    width: 976px;
    min-width: 976px;
    justify-content: center;
  }
  @media screen and (min-width: $screen-2xl) {
    width: 1032px;
    min-width: 1032px;
    justify-content: center;
  }

  @media screen and (min-width: $screen-3xl) {
    width: 1092px;
    min-width: 1092px;
    justify-content: center;
  }
}

.container::-webkit-scrollbar {
  display: none;
}
