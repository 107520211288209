.captchaContainer {
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  border-radius: 4px;
  transition: all 0.6s ease-in-out;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  /* Reveal Message */
  background: var(--captchaInputBG);
  z-index: 1;
}
.captchaTarget {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: gray;
  opacity: 0;
  z-index: 1;
}
.captchaContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 13px 4px 21px;
  margin: 0px;
}

.captchaContentCheckboxlabel {
  padding: 0px;
  padding-left: 10px;
  font-family: "AppleSDGothicNeo-SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--loginInputColor);
}

@media screen and (max-width: 673px) {
  .captchaContainer {
    background: var(--captchaInputMobileBG);
  }
}
