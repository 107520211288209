$inactive-color: #8d8d8d;

.container {
  padding: 24px 16px 20px 32px;
  border-radius: 8px;

  box-sizing: border-box;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border: solid 1px #e6e6e6;

  font-family: AppleSDGothicNeo-SemiBold;

  display: flex;
  flex-flow: column wrap;
  row-gap: 8px;

  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    top: 27px;
    left: 14px;
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #02aeef;
  }

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #e3e3e3;
    top: 27px;
    bottom: 26px;
    left: 17.5px;
  }

  &.forecast {
    &::before {
      bottom: 31.5px;
    }
  }
}

.row {
  height: 16px;
  display: flex;
  align-items: center;
  align-content: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  span {
    font-size: 13px;
    margin: 0px 4px;
  }

  .team {
    color: var(--tabsColor);
  }

  .vs {
    color: #fd8f1f;
    font-family: AppleSDGothicNeo-Bold;
  }
}

.inactiveRow {
  span {
    color: $inactive-color;
  }
}

.odds {
  font-family: AppleSDGothicNeo-Bold;
  font-size: 13px;
  box-sizing: border-box;
  padding: 2px 4px 0px 4px;
  min-width: 41px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: solid 1px #00acee;

  color: #00acee;
}

.inputAmountContainer {
  cursor: pointer;
  width: 120px;
  height: 16px;
}

.inputAmount {
  width: 112px;
  border-bottom: solid 1px #ff8f1f;
  line-height: 16px;

  font-size: 13px;

  display: flex;
  justify-content: flex-end;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  column-gap: 8px;

  span {
    color: #00acee;
  }

  padding-right: 8px;
}

.leagueName {
  color: var(--tabsColor);
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 13px;
}

.marketName {
  border-radius: 6px;
  background-color: #ef8340;
  color: white;
  font-size: 13px;
  padding: 7px 9px 5px 9px;
  height: 25px;
  box-sizing: border-box;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  min-width: 46px;
  text-align: center;
}

.oddsName {
  color: #00acee;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 130px;
}

.divider {
  width: 0;
  border-right: solid 1px #d1d1d1;
  height: 14px;
  margin: 0px 4px;
}

.buttons {
  height: 20px;

  position: absolute;
  top: 45px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 66px;
}

.deleteButton {
  width: 13px;
  height: 15px;
  cursor: pointer;
}

.line {
  position: absolute;
  top: 32px;
  left: 13.5pt;
  z-index: 2;

  height: 0px;
  border-left: solid 1px #e3e3e3;
  box-sizing: content-box;
}

.circle {
  width: 8px;
  height: 8px;
  background-color: #00acee;
  border-radius: 4px;

  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 3;
}

.expire {
  color: #fa3939;
  position: relative;
  font-size: 10px;
  display: flex;
  align-items: center;
  top: 8px;
  left: -12px;
  transition: 0.2s ease-out;
  overflow: hidden;

  img {
    margin-right: 6px;
  }
}

.rowLine {
  position: absolute;
  box-sizing: border-box;
  transform: translate(-14px, 0);
  bottom: 50%;

  border-left: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;

  height: 80px;
  width: 9px;
}

@keyframes expand {
  0% {
    height: 10px;
    width: 0;
  }
  100% {
    height: 60px;
  }
}

.inactiveContainer {
  background-color: #f5f5f5;
}

.inactiveBg {
  background-color: $inactive-color;
}

.inactiveBorder {
  border-color: $inactive-color;
}

.inactiveText {
  color: $inactive-color;
}

.forecastOdds {
  @extend .marketOdds;
  width: 100%;
  display: flex;
  position: relative;
}

.forecastRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  height: auto;
  margin-top: 8px;
}

.forecastPosition {
  width: 46px;
  height: 25px;
  border-radius: 6px;
  background-color: #ef8340;
  color: white;
  font-size: 13px;
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  line-height: 13px;

  span {
    height: 13px;
  }
}

.forecastName {
  height: 25px;
  display: flex;
  align-items: center;
  font-family: AppleSDGothicNeo-Medium;
  color: #00acee;
  font-size: 14px;
}

.oddsCol {
  display: flex;
  flex-direction: column;
  width: calc(100% - 44px);
}

.oddsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
}

.forecastLine {
  width: 8px;
  background-color: transparent;
  position: absolute;
  height: 50%;
  border-left: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;

  left: -13.5px;
  bottom: calc(100% - 12.5px);
  height: 120%;
}

.forecastRow:nth-child(3) {
  margin-top: 12px;
  .forecastOdds {
    .forecastLine {
      height: 55px;
    }
  }
}

.showOdds {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #00acee;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 12px;
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;
}

.showOddsModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 359px;
  height: 338px;
  background-color: white;
  border-radius: 8px;
  z-index: 10;
}

.showOddsHeader {
  height: 40px;
  background-color: #00acee;
  color: white;

  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 13px;
  padding: 15px 21px 11px 21px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  position: relative;

  span {
    height: 13px;
  }
}

.showOddsRow {
  display: flex;
}

.showOddsHeaderGrid {
  display: grid;
  grid-template-columns: 80px 1fr 60px 1fr;
  grid-auto-rows: min-content;
}

.showOddsGrid {
  display: grid;
  grid-template-columns: 80px 1fr 60px 1fr;
  grid-auto-rows: min-content;
  min-height: 200px;
  border-bottom: solid 1px #e6e6e6;
  max-height: 200px;
  overflow: scroll;
}

.showOddsColumnHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;

  font-family: AppleSDGothicNeo-SemiBold;
  color: #00acee;
  font-size: 12px;

  border-bottom: solid 1px #e6e6e6;
  margin-bottom: 4px;
}

.showOddsColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 12px;
}

.notices {
  padding: 12px 16px;
  list-style: "*";

  li {
    padding: 0px 8px;
    font-size: 10px;
    color: #00acee;
    width: 210px;
  }
}

.blanket {
  position: fixed;
  z-index: 9;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.08);
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
  padding: 8px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 8px;

  svg {
    position: relative;
    top: -2px;
  }

  .leagueName {
    line-height: 16px;
  }

  &.soccer {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &.basketball {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &.horseracing {
    svg {
      height: 16px;
      width: 22px;
    }
  }

  &.greyhounds {
    svg {
      height: 16px;
      width: 22px;
    }
  }

  &.baseball {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &.inActive {
    span {
      color: #8d8d8d;
    }
  }
}

.timer {
  font-size: 13px;
  color: #777;
  font-family: AppleSDGothicNeo-SemiBold;
}

.matchDetails {
  display: flex;
  align-items: center;

  .colLeft {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
  }

  .betInput {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: AppleSDGothicNeo-SemiBold;
    column-gap: 5px;
  }
}

.homeAway {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
  max-width: 235px;

  .teamImg {
    height: 16px;
    &.inActive {
      filter: saturate(0);
    }
  }

  .team {
    position: relative;
    top: 1px;
    line-height: 16px;
    font-size: 13px;
    font-family: AppleSDGothicNeo-SemiBold;
  }

  .vs {
    position: relative;
    top: 1px;
    line-height: 16px;
    font-size: 13px;
    font-family: AppleSDGothicNeo-Bold;
  }

  .teamRow {
    display: flex;
    column-gap: 8px;
  }
}

.marketDetails {
  display: flex;
  flex-direction: column;
  // margin-top: 12px;
  row-gap: 8px;

  .title {
    line-height: 16px;
    font-size: 13px;
    font-family: AppleSDGothicNeo-SemiBold;
  }
}

.marketOddsRow {
  display: flex;
  justify-content: space-between;
  position: relative;

  .marketOdds {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .oddsName {
    position: relative;
    top: 1px;
    font-size: 14px;
    font-family: AppleSDGothicNeo-Medium;
  }

  .marketName {
    position: relative;
    left: -2px;
    font-family: AppleSDGothicNeo-SemiBold;
  }
}

.expectedWinning {
  position: relative;
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  span {
    font-size: 13px;
    font-family: AppleSDGothicNeo-SemiBold;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0px;
    background-image: linear-gradient(
      to right,
      #e3e3e3 0%,
      #e3e3e3 50%,
      transparent 50%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }

  &::after {
    content: "";
    position: absolute;
    left: -14px;
    height: 1px;
    width: 8px;
    background: #e3e3e3;
  }

  .header {
    display: flex;
    column-gap: 16px;

    align-items: center;
  }
}
.failedTextInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #fa3939;
  border-radius: 5px;
  padding: 10px;
  > span {
    color: #fa3939;
    font-size: 14px;
  }
}
.successTextInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #00acee;
  border-radius: 5px;
  padding: 10px;
  > span {
    color: #00acee;
    font-size: 14px;
  }
}
