.container {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  transition: 0.2s ease-in-out all;
  background-color: #00abee00;
  cursor: pointer;
  color: #ffffff;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;
  font-family: AppleSDGothicNeo-Semibold;
  padding-left: 12px;
  padding-right: 12px;

  &::before {
    transition: 0.2s ease-in-out all;
    content: " ";
    left: 0px;
    bottom: 0px;
    position: absolute;
    margin-left: 4px;
    margin-right: 4px;
    width: calc(100% - 8px);
    height: 6px;
    background-color: #008fc700;
    border-radius: 9px;
  }

  &:hover {
    // background-color: #0184bf;
    color: var(--headerHoverColor);
  }

  &:active {
    color: var(--headerActiveColor);
  }

  &.active {
    color: var(--headerActiveColor);
    background-color: #00abee00;

    &::before {
      background: var(--buttonActivebottomLine);
    }
  }
}

.label {
  transition: 0.2s ease-in-out opacity;
  width: fit-content;
  height: fit-content;
}

.value {
  font-size: 14px;
  font-family: AppleSDGothicNeo-Bold;
  line-height: 19px;
}
