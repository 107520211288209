.container {
  position: absolute;
  height: calc(100%);
  border-radius: 16px;
  overflow: hidden;
  inset: 0px;
  pointer-events: none;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  img {
    opacity: 0;
    position: relative;
    transition: all 0.4s ease-in-out;
    transform: translate(83%, 55%);
    &.login {
      opacity: 1;
      transform: translate(96%, 7%);
    }
    &.findId {
      opacity: 1;
      transform: translate(96%, 0%);
    }
    &.findPassword {
      opacity: 1;
      transform: translate(96%, 30%);
    }
  }
}
