@import "src/styles/variables";

.container:not(.openGame) {
  width: 100%;
  min-width: 375px;
  margin-top: 108px;
  > div {
    overflow: visible !important;
    height: unset !important;
  }

  @media screen and (min-width: 1000px) {
    margin-top: 56px;
  }
  &.miniGames {
    overflow: hidden;
  }

  &.notHome {
    margin-top: 56px;
    background-color: var(--bodyBackground);
  }

  &.openBetSlip {
    animation: openBetSlip 400ms ease-out forwards;
  }

  &.promotions {
    overflow: hidden;
  }
}

.container {
  &.freeBet365 {
    width: 100%;
    min-width: 375px;
    margin-top: 0px;
    > div {
      overflow: visible !important;
      height: unset !important;
    }

    &.openBetSlip {
      animation: openBetSlip 400ms ease-out forwards;
    }
  }
}

.miniGames {
  width: 100%;
  min-width: 375px;
}

.panelWrapper {
  width: 100% !important;
}

.navBodyWrapper {
  width: 100%;
  height: 100%;

  > div {
    position: relative;
    z-index: 0;
  }
}

.sliderLoader {
  position: fixed !important;
  top: 37vh;
  left: 0;
  right: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 600ms ease forwards;
  animation-delay: 600ms;
  img {
    width: 40px;
    height: 40px;
  }
}

.bodyBg {
  position: fixed;
  background-repeat: no-repeat;
  z-index: -1;
  background: var(--bodyBackground);
  inset: 0;
  width: 100%;

  &.liveSports,
  &.sports,
  &.bet365 {
    background-color: #fff;
  }

  &.home,
  &.notHome,
  &.freeGames,
  &.miniGames {
    background: var(--bodyBackground);
  }

  &.casino {
    background: var(--bodyBackground);
    // top: 185px;
    height: 100vh;
    width: 100%;
    // background-image: url("/assets/img/casino_bg.png");
    background-size: 400px;
    background-position-y: 185px;

    @media screen and (min-width: $screen-sm) {
      // top: 135px;
      background-size: 600px;
      // background-position-y: -140px;
      background-position-x: calc(100% + 300px);
      background-repeat: no-repeat;
      background-position-y: 5px;
    }

    @media screen and (min-width: $screen-lg) {
      // top: 135px;
      height: calc(100vh - 08px - 56px);
      background-size: 100%;
      background-position-y: 100%;
      background-position-x: 0;
      background-repeat: no-repeat;

      &::after {
        position: absolute;
        height: 520px;
        width: 700px;
        top: 0px;
        right: 0px;
        content: " ";
        background-position-y: 135px;
        background-position-x: 50px;
        // background-image: url("/assets/img/casino_bg.png");
        background-size: 800px;
        background-repeat: no-repeat;
      }
    }
  }

  &.slots {
    background: var(--bodyBackground);
    // top: 185px;
    min-height: 100vh;
    width: 100%;
    // background-image: url("/assets/img/slots/slots-bg.png");
    background-size: 400px;
    // background-position-y: -140px;
    background-position-x: 160px;
    background-position-y: 45px;

    @media screen and (min-width: $screen-sm) {
      // top: 135px;
      background-size: 600px;
      // background-position-y: -140px;
      background-position-y: 5px;
      background-position-x: calc(100% + 300px);
      background-repeat: no-repeat;
    }

    @media screen and (min-width: $screen-lg) {
      // top: 135px;
      height: calc(100vh - 08px - 56px);
      background-size: 800px;
      // background-position-y: calc(100% + 200px);
      background-position-y: 100%;
      background-position-x: 0;
      background-repeat: no-repeat;

      &::after {
        position: absolute;
        height: 400px;
        width: 700px;
        top: 0px;
        right: 0px;
        content: " ";
        background-position-y: -300px;
        background-position-x: 250px;
        // background-image: url("/assets/img/slots/slots-bg.png");
        background-size: 800px;
        background-repeat: no-repeat;
      }
    }
  }

  &.slotSearch {
    background-color: transparent;
    background-position-y: 133px;
    @media screen and (min-width: $screen-lg) {
      background-position-y: calc(100% + 335px);
    }
  }

  &.slotSearchResults {
    background-position-y: 130px;
  }

  &.eos {
    background-color: #051527;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes openBetSlip {
  0% {
    margin-top: 108px;
  }
  100% {
    margin-top: 0px;
  }
}
@media screen and (min-width: 674px) {
  .slotsPage {
    min-height: 110vh !important;
  }

  .myPage {
    min-height: 905px !important;
  }
}

@media screen and (max-width: 674px) {
  .slotsPage {
    min-height: 110vh !important;
  }

  .myPage {
    min-height: 734px !important;
  }

  .homePage {
    min-height: 83vh !important;
  }
}
