.otpContentIOS {
  display: flex;
  justify-content: center;
  /* padding-top: 22px; */
}

.otpContent {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: transparent;
  box-shadow: var(--otpBoxshadowColor);
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;
  &.expand {
    opacity: 1;
    height: 244px;
  }
  color: var(--userCardTitlesColor);
}

.otpTitleIOS {
  display: flex;
  justify-content: center;
  font-family: "AppleSDGothicNeo-SemiBold";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  color: var(--userCardTitlesColor);
  padding-top: 22px;
}

.otpTitle {
  display: flex;
  justify-content: center;
  font-family: "AppleSDGothicNeo-SemiBold";
  font-size: 18px;
  text-align: center;
  color: var(--userCardTitlesColor);
  padding-top: 22px;
}
.otpMessage {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  font-family: "AppleSDGothicNeo-Medium";
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: var(--userCardTitlesColor);
  margin-top: 6px;
  margin-bottom: 22px;
  white-space: pre-wrap;
}

.otpTimer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.otpTimerIcon {
  margin-right: 14px;
}

.otpTimerInfo {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  font-family: "AppleSDGothicNeo-Medium";
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: var(--userCardTitlesColor);

  display: flex;
}

.otpTimerCountdown {
  color: #fa3939;
  padding-left: 4px;

  display: flex;
}

.otpInputs {
  /* margin: 18px 0 18px; */
  margin: 15px 0 10px;
  display: flex;
  justify-content: center;
}

.otpInput {
  width: 38px;
  height: 54px;
  padding: 0px;
  margin: 0px 7px;
  border-radius: 5px;
  background-color: transparent;
  font-size: 29px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  opacity: 1;
  transition: all 0.6s ease-in-out;
  background-clip: padding-box;
  border: 1px solid rgba(127, 127, 127, 09);
  color: var(--userCardTitlesColor);

  &.error {
    border: 1px solid red !important;
  }

  &.valid {
    border: 1px solid #00ce18 !important;
  }
}

.otpInput:focus {
  border: 1px solid #00acee;
}

.otpInputsFormInput {
}

.otpErrors {
  color: #fa3939;
  text-align: center;
  font-size: 12px;
  font-family: "AppleSDGothicNeo-Medium";
  margin-bottom: 10px;
}

.otpErrorsMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.otpErrorsMessageActive {
  opacity: 1;
}

.otpWrapper {
  opacity: 0;
  transform: scale(0.9);
  box-sizing: border-box;
  transition-property: opacity, transform, translateY;
  transition-duration: 0.8s, 0.5s;
  transition-delay: 0.1s, 0.4s;
  transition-timing-function: ease-in-out;
}
.otpWrapperActiveIOS {
  opacity: 1;
  transform: scale(0.98);
}

.otpWrapperActive {
  opacity: 1;
  transform: scale(0.97);
  width: 100%;
  height: 244px;
  display: flex;
  flex-direction: column;
}
