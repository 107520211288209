.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid;
  margin-top: 10px;
  // padding-bottom: 20px;
  border-color: var(--modalSectionsBorderColor);
}

.title {
  font-family: AppleSDGothicNeo-Medium;
  font-size: 14px;
  line-height: 17px;
  color: var(--myInfoColor);
  padding-top: 20px;
  padding-bottom: 16px;
  padding-left: 8px;
}

.headers {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  column-gap: 4px;
  > div {
    width: 50%;
  }
}

.body {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  row-gap: 10px;
}

.row {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  column-gap: 4px;
  > div {
    width: 50%;
  }
}

.label {
  color: var(--hsitoryTabTotalHeadColor);
  font-size: 16px;
  font-family: AppleSDGothicNeo-SemiBold;
  // line-height: 15px;
  white-space: nowrap;
  > img {
    width: 164px;
  }
}

.value {
  display: flex;
  align-items: center;
  font-family: AppleSDGothicNeo-Medium;
  font-size: 16px;
  // line-height: 17px;
  color: var(--hsitoryTabTotalHeadColor);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goldenText {
  margin-top: 1px;
  background: linear-gradient(180deg, #fffbd4 0%, #c06001 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  width: 85%;
  border: 0.5px solid var(--cashbackPopupDetailsSecondary);
}

.cashbackDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 293px;
  .infosContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px 16px 24px 16px;
    gap: 10px;
    border-radius: 16px;
    // border: 1px solid;
    // border-image: linear-gradient(to bottom, #000 0%, #253d53 100%) 1;
    background: linear-gradient(
      180deg,
      var(--cashbackPopupDetailsSecondary) -49.78%,
      var(--cashbackPopupDetailsPrimary) 101.67%
    );

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 1px; /* control the border thickness */
      background: linear-gradient(
        180deg,
        var(--cashbackPopupDetailsPrimary),
        var(--cashbackPopupDetailsSecondary)
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  }
  .headerTitle {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 26px;
    font-family: AppleSDGothicNeo-ExtraBold;
  }
  .headerDescription {
    font-size: 16px;
    font-family: AppleSDGothicNeo-SemiBold;
  }
  .infoItem {
    display: flex;
    gap: 8px;
  }
}

.dashedCard {
  margin: 10px 0;
  height: 100px;
  border-radius: 5px;
  border: 1px dashed var(--hsitoryTabTotalHeadColor);
  border-right: none;
  padding: 10px 10px;
  position: relative;
  background: radial-gradient(
      20px at right,
      #0000 97%,
      var(--betDetailsCouponCardBackground)
    )
    right;
  overflow: hidden;
  display: flex;
  align-items: center;
  .rightTopBorder {
    position: absolute;
    right: 0;
    top: -4px;
    height: 40px;
    border: 1px dashed var(--hsitoryTabTotalHeadColor);
    border-right: none;
  }
  .rightBottomBorder {
    position: absolute;
    right: 0;
    bottom: -4px;
    height: 40px;
    border: 1px dashed var(--hsitoryTabTotalHeadColor);
    border-right: none;
  }

  .flexContainer {
    flex: 1;
    display: flex;
    align-items: center;
    .title {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 80%;
      }
    }
    .values {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 5px;

      .infoItem {
        display: flex;
        gap: 8px;
      }
    }
  }
}
.dashedCard::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 40px;
  border: 1px dashed var(--hsitoryTabTotalHeadColor);
  border-right: none;
  border-bottom: none;
  transform: rotate(-45deg);
  width: 40px;
}

.win {
  color: #00acee;
}
