.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 22vw;
  min-height: 22vw;
  max-height: 22vw;
}

.fullWidthImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 2;
}

.skeletonContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 22vw;
  max-height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.skeleton {
  height: 100%;
  width: 100%;
  margin: 10px 0;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 75%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media (max-width: 841px) {
  .container {
    height: unset;
    min-height: unset;
    max-height: unset;
  }
  .skeletonContainer {
    height: 40vw;
    max-height: 40vw;
  }
}
