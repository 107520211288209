.header {
  /* transition: 0.4s linear; */
  transition: 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header.test {
  background-color: red;
}

.headerInactive {
  /* -webkit-transform: translateY(-100%); */
  /* transform: translateY(-100%); */
  /* opacity: 0; */
  /* visibility: hidden; */
}

.headerActive {
  /* -webkit-transform: translateY(0%) !important; */
  /* transform: translateY(0%) !important; */
  /* opacity: 1; */
  /* visibility: visible; */
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  /* -webkit-transform: translateY(-100%);
  transform: translateY(-100%); */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--loginInputColor) !important;
  -moz-text-fill-color: var(--loginInputColor) !important;
}
