.inputBordered {
  position: relative;
  padding: 0px;
  margin: 0px 0px 4px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  transition: all 0.4s ease-in-out;
  /* Reveal Message */
  // background-color: #ffffff;
  z-index: 1;
  height: 56px;

  &.inputBorderedValid {
    box-shadow: inset 0 0 0 1px #00acee;
  }

  &.inputBorderedActive {
    box-shadow: inset 0 0 0 2px #00acee;
  }

  &.inputBorderedError {
    box-shadow: inset 0 0 0 2px var(--inputFieldErrorBorderColor);
  }
}

.formInput {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 54px;
  color: var(--loginInputColor);
}
.formInput::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 1px;
}

.formInputIOS {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 57px;
  color: var(--loginInputColor);
}
.formInputIOS::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput::-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.formInput:-ms-input-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.formInput:-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.labelContainer {
  position: absolute;
  display: flex;
  top: -8px;
  left: 11px;
  background-color: transparent;
  box-sizing: content-box;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1;
  cursor: default;
  margin: 0px;
  padding: 0px;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: rgb(82, 82, 82);
  overflow: hidden;
}
.label {
  white-space: nowrap;
  background: var(--loginInputLableBG);
  padding: 0px 5px;
  color: var(--loginInputColor);
}
.labelInfo {
  font-family: AppleSDGothicNeo-SemiBold;
  white-space: nowrap;
  overflow: hidden;
  background: var(--loginInputLableBG);
  max-width: 0px;
  padding-right: 0px;
  color: rgb(82, 82, 82);
  transition: all 0.4s ease-in-out;
  color: var(--loginInputColor);

  &.labelInfoActive {
    max-width: 100%;
    padding-right: 5px;
  }

  &.labelInfoError {
    max-width: 100%;
    padding-right: 5px;
    color: var(--inputFieldErrorBorderColor);
  }
}

.defaultMessage {
  position: relative;
  display: flex;
  opacity: 0;
  padding-left: 4px;
  height: 28px;
  padding-top: 1px;
  transition: all 0.3s ease-out;

  &.defaultMessageActive {
    opacity: 1;
  }

  &.defaultMessageError {
    opacity: 1;
    height: 33px;
    color: var(--inputFieldErrorColor);
  }
}

.defaultHelper {
  display: flex;
  position: absolute;
  line-height: 12px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  align-items: center;
  .icon {
    position: relative;
    top: 1px;
  }
}
.defaultHelperDefault {
  display: flex;
  padding-top: 4px;
  position: relative;
  line-height: 15px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  transition: opacity 0.2s ease-out;
}
.defaultHelperActive {
  transition: all 1.5s ease-out;
}
.defaultHelperError {
  transition: all 1.5s ease-out;
}

.defaultHelperValid {
  transition: all 1.5s ease-out;
}

.defaultHelperText {
  padding-left: 4px;
}
.defaultHelperTextValid {
  padding-left: 4px;
  color: #00acee;
}
.defaultHelperTextError {
  padding-left: 4px;
  color: var(--inputFieldErrorColor);
}

.parenStyle {
  color: rgb(82, 82, 82);
}

@media screen and (max-width: 673px) {
  .label {
    background: var(--mobileRegisterInputLableBG2);
  }
  .labelInfo {
    background: var(--mobileRegisterInputLableBG2);
  }
}
