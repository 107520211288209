.flashMessageContainerIOS {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1111;
  background: #000;
  height: 90px;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.flashMessageContainerAndroid {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1111;
  background: #000;
  height: 90px;
}

.flashMessageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1111;
  background: #000;
  height: 90px;
}

.flashMessageHeight {
  height: 0px !important;
}

.flashMessageContainer > div,
.flashMessageContainerIOS > div {
  width: 100%;
  height: 100%;
}

.flashMessageActive {
  transform: translateY(0);
  transition: all 0.4s ease-in-out;
}

.flashMessageContent {
  position: relative;
  z-index: 30;
  height: 100%;
  flex: 1;
}

.flashMessageInfo {
  padding: 8px 50px 0;
}
.flashMessageValue {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.7px;
  color: #ffffff;
  text-align: center;
  font-family: "AppleSDGothicNeo-Medium";
  opacity: 1;
}

.flashMessageValueAlert {
  color: #fa3939;
}

.flashMessageValueSuccess {
  color: #00ce18;
}

.flashMessageClose {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 18px;
}

.flashMessageClose > svg {
  width: 14px;
  height: 14px;
}
