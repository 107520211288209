@import "src/styles/variables";

.container {
  display: flex;
  height: 100%;
  position: relative;
}

.navButton {
  all: unset;
  transition: 0.2s ease-in-out all;
  background-color: #00abee00;
  cursor: pointer;
  color: #ffffff;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;
  font-family: AppleSDGothicNeo-Semibold;
  padding-left: 10px;
  padding-right: 10px;
}

.isNew {
  position: absolute;
  font-size: 10px;
  font-family: AppleSDGothicNeo-SemiBold;
  right: 2px;
  top: 3px;
  color: white;
  padding: 0px 4px;
  padding-top: 1px;
  opacity: 0.9;
  transform: skewX(-15deg);
  background: linear-gradient(90deg, #de1515, #de1515, #fcfcfc99, #de1515, #de1515)
    left/70px 100%;

  background-color: #de1515;
  animation: change 1200ms ease-in-out infinite;
}

.isFree {
  position: absolute;
  font-size: 8px;
  font-family: AppleSDGothicNeo-Bold;
  right: 3.5px;
  top: 3px;
  color: white;
  padding: 1px 5px;
  padding-top: 3px;
  opacity: 0.9;
  transform: skewX(-30deg);
  background: linear-gradient(90deg, #2d94cb, #2d94cb, #fcfcfc40, #2d94cb, #2d94cb)
    left/70px 100%;

  background-color: #2d94cb;
  animation: change 1200ms ease-in-out infinite 600ms;
  border-radius: 4px 0px 4px 0px;

  span {
    display: flex;
    transform: skewX(30deg);
  }
}

@keyframes change {
  from {
    background-position: 0px;
  }
  to {
    background-position: 70px;
  }
}

@media screen and (min-width: $screen-lg) {
  .isNew {
    top: 5px;
    right: -4px;
  }

  .isFree {
    top: 5px;
    right: 5px;
    // right: -4px;
  }
}

@media screen and (min-width: $screen-xl) {
  .navButton:hover {
    cursor: pointer;
    // background-color: #0184bf;
    color: var(--headerHoverColor);
  }
}

@media screen and (min-width: $screen-xl) {
  .navButton:active {
    // background-color: #06aaeb;
    color: var(--headerHoverColor) !important;
  }
}
