.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 7px 20px -11px rgba(0,0,0,.5);
  // transition: background-color 0.2s;
  column-gap: 8px;
  &.confirm {
    background: var(--dataPickerModalConfirmButtonBackground);
    color: var(--dataPickerInputColor);
    &:active {
      background: var(--dataPickerModalConfirmButtonBackground);
    }
  }

  &.cancel {
    background: var(--dataPickerModalCancelButtonBackground);
    color: #aeaeae;
    &:active {
      background: var(--dataPickerModalCancelButtonBackground);
    }
  }

  span {
    font-family: AppleSDGothicNeo-Medium;
    font-size: 15px;
    line-height: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.delete {
    background-color: var(--deleteButtonBG);
    color: var(--deleteButtonColor);

    &:active {
      background-color: var(--deleteButtonBG);
    }
  }

  &.read {
    background: var(--readbuttonBackground);
    color: var(--buttonLabel);

    &:active {
      background: var(--readbuttonBackground);
    }
  }

  &.disabled {
    cursor: default;
    background: var(--disabledButtonBG) !important;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background: #f4f4f4;
    color: var(--disabledButtonColor);
  }

  &.loginDisabled {
    background: var(--disabledButtonBG);
    color: var(--disabledButtonColor);
  }

  &.registerBtnDisabled {
    background: red;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background: var(--dataPickerModalConfirmButtonBackground);
      color: var(--dataPickerInputColor);
    }
  }

  &.cancel {
    &:hover {
      color: #aeaeae;
      background: var(--dataPickerModalCancelButtonBackground);
    }
  }

  .delete {
    &:hover {
      background: var(--deleteButtonBG) !important;
    }
  }

  .read {
    &:hover {
      color: var(--buttonLabel);
      background: var(--readbuttonBackground);
    }
  }

  .disabled {
    &:hover {
      cursor: not-allowed;
      background: var(--disabledButtonBG);
      color: var(--disabledButtonColor);
    }
  }

  .loginDisabled {
    &:hover {
      background: var(--disabledButtonBG);
      color: var(--disabledButtonColor);
    }
  }
}
