@import "src/styles/variables";

:global {
  .ReactModal__Body--open {
    overflow: hidden;
    height: 100vh;
  }
}

.container {
  display: flex;
  border-radius: 0px;
  overflow: visible;
  transition: all 0.6s ease;
  width: calc(100% - 32px) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  align-items: center;
  justify-content: center;
  width: 100vw;
  @media screen and (min-width: $screen-xl) {
    max-width: 1058px;
  }
}

.modalWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: default;
  z-index: 1;
}

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 201;
  background-color: rgba(0, 0, 0, 0.6) !important;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &.show {
    opacity: 1;
  }
}

.overlayBlanket {
  position: fixed;
  inset: 0;
  z-index: 0;
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0px);
  }
}
