.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 8px;
  padding-bottom: 32px;
}

.noSlips {
  width: 100%;
  height: calc(100vh - 117px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-family: AppleSDGothicNeo-SemiBold;
    font-size: 13px;
    line-height: 16px;
    color: #777777;
    width: 205px;
    text-align: center;
  }
}

.continue {
  border: none;
  margin-top: 27px;
  background-color: #00acee;
  border-radius: 8px;
  height: 32px;
  width: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 14px;
  box-shadow: 0px 3px 6px #00000014;
}

.continue:active {
  background-color: #1097cd;
}
