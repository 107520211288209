@import "src/styles/variables";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.figure {
  margin: 0;
  position: relative;

  &.animate {
    svg .circleFg {
      // animation: offset 1s ease-in-out forwards;
      // animation-delay: 600ms;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.circleBg {
  cx: 50%;
  cy: 50%;
  fill: none;
}

.circleFg {
  @extend .circleBg;
  transition: 1000ms linear all;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  &.noTransition {
    transition: 0ms linear all;
  }
}

@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

.content {
  position: absolute;
  inset: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: AppleSDGothicNeo-Bold;
  font-size: 13px;
  line-height: 16px;

  @media screen and (min-width: $screen-sm) {
    font-size: 20px;
    line-height: 24px;
  
  }
}
