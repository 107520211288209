.styledLink {
  font-family: AppleSDGothicNeo-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: var(--loginInputColor);
  text-decoration: underline;
}
