@import "src/styles/variables";

.container {
  display: flex;
  justify-content: center;
  height: 56px;
  width: 100%;
  background: var(--bg-color);
  // background: linear-gradient(90deg, #00acee, #13b8f8);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  box-sizing: border-box;
  border-left: none;
  border-right: none;
  overflow: visible;
  min-width: 375px;
  &::before {
    content: " ";
    top: -56px;
    position: absolute;
    background: #fcfcfc;
    width: 100%;
    height: 56px;
  }
}

.content {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100vw;
  position: relative;
  min-width: 375px;
  @media screen and (min-width: $screen-xl) {
    max-width: 1090px;
  }
}

.burgeMenu {
  transition: 0.2s ease-in-out all;
  background-image: url("/assets/img/burger-menu.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #00acee;

  width: 65px;

  button {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $screen-lg) {
    background-image: unset;
    display: none;
  }
}

.hide {
  opacity: 1;
  animation: fadeOut 400ms ease forwards;
}

.fixed {
  position: fixed;
  top: 0px;
}

.burgeMenu:active {
  background-color: #0184bf;
}

.rightBar {
  display: flex;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wallet {
  opacity: 0 !important;
  pointer-events: none !important;
  &.show {
    opacity: 1 !important;
    pointer-events: fill !important;
  }
}

.liveChatBar {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #ffffff;
  position: relative;
  right: 0px;
  font: normal normal 600 15px/19px Apple SD Gothic Neo;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  &::before {
    transition: 0.2s ease-in-out all;
    content: " ";
    left: 0px;
    bottom: 0px;
    position: absolute;
    margin-left: 4px;
    margin-right: 4px;
    width: calc(100% - 8px);
    height: 6px;
    background-color: #008fc700;
    border-radius: 9px;
  }

  &:hover {
    // background-color: #0184bf;
    path {
      fill: var(--headerHoverColor);
      transition: 0.2s ease-in-out all;
    }
  }

  &:active {
    background-color:transparent;
  }

  &.active {
    background-color: #00abee00;

    &::before {
      background-color: var(--headerHoverColor);
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .wallet {
    // right: 48px !important;
  }
}

@media screen and (min-width: 1025px) {
  .wallet {
    // right: 88px !important;
  }
}
