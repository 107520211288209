@import "src/styles/variables";

.formRegisterWrapper {
  padding: 0;
}
.inputBox {
  position: relative;
  // padding: 0 24px;
  margin: 0 24px;
}
.registerCheckbox {
  padding: 24px 0px 26px 44px;
}

.registerTerms {
  font-size: 12px;
  color: var(--loginInputColor);
  font-family: AppleSDGothicNeo-Regular;
  padding-left: 10px;
  padding-right: 10px;
}
.registerTermsLink {
  font-size: 12px;
  color: var(--loginInputColor);
  cursor: pointer;
  text-decoration: underline;
}
.registerTermsFirst {
  margin-left: 10px;
}
.button {
  height: 52px;
  width: calc(100% - 48px);
  margin: auto;
}

.highlight {
  color: #e8690f;
}

@media screen and (min-width: $screen-sm) {
  .registerCheckbox {
    padding: 24px 0px 26px 44px;
  }
}

@media screen and (min-width: $screen-md) {
  .registerCheckbox {
    padding: 24px 32px 26px 32px;
  }
}

@media screen and (min-width: $screen-lg) {
  .registerCheckbox {
    padding: 24px 0px 26px 44px;
  }
}
