.container {
  display: flex;
  border-radius: 0px;
  overflow: visible;
  transition: all 0.6s ease;
  width: calc(100% - 32px) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  justify-content: center;
  align-items: center;
}

.btnGroup {
  display: flex;
  column-gap: 8px;
  justify-content: center;
}

.modalWrapper {
  // transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 40px 38px;
  border-radius: 10px;
  background: var(--myPageMobileBG);
  max-width: 265px;
  width: 100%;
  row-gap: 24px;
  box-shadow: var(--globalAlertBoxShadow);

  &.show {
    animation: slideUp 0.2s ease-in-out forwards;
  }
}

.modalMessage {
  font-family: AppleSDGothicNeo-Medium;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--myInfoColor);
}

.modalEmbededChildDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -0.5em;
}

.modalConfirm {
  transition: all 0.2s ease-in-out;
  font-family: AppleSDGothicNeo-Medium;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  color: var(--dataPickerInputColor);
  background: var(--dataPickerModalConfirmButtonBackground);
  outline: none;
  border: transparent;
  border-radius: 4px;
  width: 131px;

  &:hover {
    background: var(--dataPickerModalConfirmButtonBackground);
  }

  &:disabled {
    background-color: var(--disabledButtonBG);
    color: var(--disabledButtonColor);
  }
}

.cancel {
  transition: all 0.2s ease-in-out;
  font-family: AppleSDGothicNeo-Medium;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  color: #aeaeae;
  background: var(--dataPickerModalCancelButtonBackground);
  outline: none;
  border: transparent;
  border-radius: 4px;
  width: 131px;

  &:hover {
    color: var(--sidebarItemColor);
    background: var(--dataPickerModalCancelButtonBackground);
  }
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 201;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  // @media screen and (min-width: $screen-sm) {
  //   display: flex;
  //   justify-content: center;
  //   animation-delay: 300ms !important;
  // }

  &.show {
    opacity: 1;

    // animation: blurIn 1s ease-in-out forwards;
  }
}

@keyframes blurIn {
  0% {
    backdrop-filter: blur(0px);
  }

  100% {
    backdrop-filter: blur(6px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0px);
  }
}
