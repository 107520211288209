.mobileContainer {
  display: flex;
  align-items: flex-start;
  // background-color: #ffffff;
  position: relative;
  z-index: 1;
}
.mobileInputContainer {
  flex: 1;
}
.mobileButtonContainer {
  margin-left: 10px;
  position: relative;
  z-index: 1;
}

.inputBordered {
  position: relative;
  padding: 0px;
  margin: 0px 0px 4px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  transition: all 0.4s ease-in-out;
  /* Reveal Message */
  background: var(--loginInputFieldBG);
  z-index: 1;
  height: 56px;
}

.inputBorderedError {
  box-shadow: inset 0 0 0 2px var(--inputFieldErrorBorderColor);
  transition: all 0.4s ease-in-out;
}

.inputBorderedActive {
  box-shadow: inset 0 0 0 2px #00acee;
  transition: all 0.4s ease-in-out;
}

.labelContainer {
  position: absolute;
  display: flex;
  top: -8px;
  left: 11px;
  background-color: transparent;
  box-sizing: content-box;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1;
  cursor: default;
  margin: 0px;
  padding: 0px;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: rgb(82, 82, 82);
  overflow: hidden;
}
.label {
  white-space: nowrap;
  background: var(--loginInputLableBG);
  padding: 0px 5px;
  color: var(--loginInputColor);
  border-radius: 5px;
}
.labelInfo {
  font-family: AppleSDGothicNeo-SemiBold;
  white-space: nowrap;
  overflow: hidden;
  background: var(--loginInputLableBG);
  max-width: 0px;
  padding-right: 0px;
  transition: all 0.4s ease-in-out;
  color: var(--loginInputColor);
}
.labelInfoDefault {
  color: var(--loginInputColor);
  max-width: 0px;
  transition: all 1.5s ease-in-out;
}
.labelInfoDefaultImportant {
  color: var(--loginInputColor) !important;
  max-width: 0px !important;
  padding-right: 0px !important;
  transition: all 0.4s ease-in-out;
}
.labelInfoActive {
  color: var(--loginInputColor) !important;
  max-width: 100%;
  padding-right: 5px;
  transition: all 1s ease-in-out;
}

.labelInfoError {
  color: var(--inputFieldErrorBorderColor);
  max-width: 100%;
  padding-right: 5px;
  transition: color 0.4s ease-in-out 0s;
}

.formInput {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 54px;
  color: var(--loginInputColor);
}
.formInput::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 1px;
}

.formInputIOS {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 57px;
  color: var(--loginInputColor);
}
.formInputIOS::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  padding-top: 1px;
}

.formInput::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput::-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput:-ms-input-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.formInput:-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}

.mobileLock {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
}

.mobileMessage {
  display: flex;
  height: 28px;
  position: relative;
  opacity: 1;
  padding-left: 4px;
  transition: all 0.2s ease-out;
}
.mobileMessageDefault {
  height: 25px !important;
  opacity: 1;
}
.mobileMessageSubmitting {
  height: 28px !important;
  opacity: 1;
}
.mobileMessageActive {
  height: 3 !important;
  opacity: 1;
  /* transition: all 1.5s ease-out; */
  transition: all 0.5s ease-out;
}
.mobileMessageError {
  opacity: 1;
  height: 33px;
  /* transition: all 1.5s ease-out; */
  transition: all 0.5s ease-out;
}
.mobileMessageValid {
  opacity: 1;
  height: 33px;
  /* transition: all 1.5s ease-out; */
  transition: all 0.5s ease-out;
}

.icon {
  position: relative;
  top: 1px;
  svg g {
    stroke: var(--inputFieldErrorColor);
    > g:last-child > g {
      fill: var(--inputFieldErrorColor) !important;
    }
  }
}

.mobileHelper {
  display: flex;
  line-height: 12px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  overflow: hidden;
  position: absolute;
  width: 100%;
  transition: all 0.4s ease-out 0s;
  align-items: center;
}
.mobileHelperDefault {
  display: flex;
  position: relative;
  line-height: 12px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  height: 0;
  transition: all 0.2s ease-out 0s;
}
.mobileHelperActive {
}
.mobileHelperError {
  transition: all 1.5s ease-out 0s;
}

.mobileHelperValid {
  transition: all 1.5s ease-out 0s;
}

.mobileHelperText {
  padding-left: 4px;
}
.mobileHelperTextValid {
  padding-top: 1px;
  color: #00acee;
}
.mobileHelperTextError {
  padding-left: 4px;
  color: var(--inputFieldErrorColor);
}
.mobileHelperTextErrorLong {
  padding-left: 4px;
  color: var(--inputFieldErrorColor);
  position: absolute;
  left: 15px;
  width: 300px;
}

.mobileSubmitIOS {
  padding: 2px 0 0 0;
  margin: 0px;
  border: 0px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  box-sizing: border-box;
  font-size: 14px;
  width: 98px;
  height: 56px;
  max-height: 56px;
  padding-top: 2px;
  font-family: AppleSDGothicNeo-SemiBold;
  transition: all 0.3s ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  background: var(--loginInputFieldBG);
  color: var(--loginInputColor);
}

.mobileSubmit {
  padding: 1px 0 0 0;
  margin: 0px;
  border: 0px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  box-sizing: border-box;
  font-size: 14px;
  width: 98px;
  height: 56px;
  font-family: AppleSDGothicNeo-SemiBold;
  transition: all 0.3s ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  background: var(--loginInputFieldBG);
  color: var(--loginInputColor);
}
.mobileSubmitError {
  color: rgba(149, 149, 149, 0.4);
  box-shadow: var(--inputFieldErrorBorderColor) 0px 0px 0px 2px inset;
}

.mobileSubmitErrorDark {
  color: rgb(82, 82, 82);
  font-weight: 600;
  box-shadow: var(--inputFieldErrorBorderColor) 0px 0px 0px 2px inset;
}

.mobileSubmitValid {
  color: var(--loginInputColor);
  font-weight: 600;
  box-shadow: inset 0 0 0 2px #00acee;
}
.mobileSubmitFocused {
  color: var(--loginInputColor);
  box-shadow: rgb(127, 127, 127) 0px 0px 0px 1px inset;
}

.parenStyle {
  color: rgb(82, 82, 82);
}

.otpContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 0px;
  transition: height 0.6s ease-in-out, padding-bottom 0.8s ease-in-out;
  padding-bottom: 0px;

  &::before {
    transition: height 0.6s ease-in-out;
    content: "";
    position: absolute;
    top: 0px;
    left: -80px;
    right: -80px;
    height: 0px;
    background: var(--loginInputFieldBG);
    box-shadow: 0 1px #d1d1d1 inset, 0 -1px #d1d1d1 inset, 0px 0 #d1d1d1 inset,
      0px 0 #d1d1d1 inset;
  }

  &.expand {
    height: 244px;
    &::before {
      height: 244px;
    }
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 673px) {
  .label {
    background: var(--mobileRegisterInputLableBG2);
  }
  .labelInfo {
    background: var(--mobileRegisterInputLableBG2);
  }
}
