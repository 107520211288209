.actionBar {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 12px;
  min-height: 37px;
  opacity: 0;
  animation: slideDown 200ms ease-in-out forwards 1000ms;
}

.icon {
  display: block;

  g,
  path {
    transition: stroke 0.2s ease-in-out;
  }

  &:active {
    g,
    path {
      stroke: #00acee;
    }
  }
}

.countDown {
  z-index: 1;
  opacity: 0;
  bottom: 0px;
  animation: fadeIn 200ms ease-in-out forwards 200ms;
}

.closeContainer {
  position: relative;
  right: -20px;
  display: flex;
  width: 100%;
  cursor: default;
  display: flex;
  justify-content: flex-end;
  min-height: 56px;
}

.closeBtn {
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  // margin-top: -4px;
  width: 56px;
  height: 56px;
  background: none;
  border: none;
  outline: none;
  padding: 20px;
  svg {
    height: 100%;
    width: 100%;

    path {
      transition: fill 0.2s ease-in-out;
      fill: #ffffff;
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (hover: hover) {
  .closeBtn {
    &:hover {
      path {
        fill: #1fbfff;
      }
    }
  }
}

@media screen and (min-width: 674px) {
  .closeBtn {
    display: flex;
  }

  .actionBar {
    // margin-top: 48px;
    justify-content: center;
  }

  .icon {
    display: none;
  }
}

// enable for third banner
// @media screen and (min-width: 1151px) { 
//   .closeContainer {
//     right: 100px;
//   }
// }
