@import "src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.navContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px;

  &.preventScroll {
    touch-action: none;
  }

  &.hide {
    opacity: 0;
    touch-action: none;
    pointer-events: none;
    margin-top: 0px;
    height: 0px;
    animation: fadeOut 400ms ease forwards;
  }
  &.hideForFreeGames {
    display: none;
  }

  &.isBeticaOpen {
    overflow: hidden;
  }

  &.openBetSlip {
    z-index: 0;
    animation: openBetSlip 400ms ease-out forwards;
    animation-delay: 100ms;
    pointer-events: none;
  }

  &.closeBetSlip {
    animation: closeBetSlip 300ms ease-out forwards;
  }
}

.navBar {
  position: relative;
  top: 0;
  z-index: 6;
}

.navSlider {
  position: relative;
  width: 100vw;
  z-index: 5;
  box-sizing: border-box;
  // border-bottom: 4px solid #e3e3e3;
  background: #ffffff;
}

.naverBody {
  position: relative;
  z-index: 0;
  top: 0px;

  &.fullscreen {
    position: absolute;
    top: 0px;
    z-index: 7;
    // animation: fixedToPosition 1000ms ease-in-out forwards;
  }
}

.fixed {
  position: fixed;
  top: 0px;
}

.displayNone {
  opacity: 0;
  touch-action: none;
  pointer-events: none;
  margin-top: 0px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fixedToPosition {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    transform: translateY(-56px);
  }
  100% {
    opacity: 1;
    position: fixed;
    top: 0;
  }
}

@keyframes openBetSlip {
  0% {
    height: 52px;
  }
  100% {
    height: 0px;
  }
}

@keyframes closeBetSlip {
  0% {
    height: 0px;
  }
  100% {
    height: 108px;
  }
}
