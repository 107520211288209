/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

/* Apple SD Gothic Neo - Regular */
@font-face {
  font-family: "AppleSDGothicNeo-Regular";
  font-style: normal;
  font-weight: normal;
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("AppleSDGothicNeo-Regular"),
    url("/assets/fonts/applesdgothicneor.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneor.woff2") format("woff2");
}

/* Apple SD Gothic Neo - Medium */
@font-face {
  font-family: "AppleSDGothicNeo-Medium";
  font-style: normal;
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("AppleSDGothicNeo-Medium"),
    url("/assets/fonts/applesdgothicneom.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneom.woff2") format("woff2");
}

/* Apple SD Gothic Neo - Semi-Bold */
@font-face {
  font-family: "AppleSDGothicNeo-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("AppleSDGothicNeo-SemiBold"),
    url("/assets/fonts/applesdgothicneosb.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneosb.woff2") format("woff2");
}

/* Apple SD Gothic Neo - Bold */
@font-face {
  font-family: "AppleSDGothicNeo-Bold";
  font-style: normal;
  font-weight: 600;
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("AppleSDGothicNeo-Bold"),
    url("/assets/fonts/applesdgothicneob.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneob.woff2") format("woff2");
}

/* Apple SD Gothic Neo - ExtraBold */
@font-face {
  font-family: "AppleSDGothicNeo-ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-display: swap; /* or block, swap, fallback, optional */
  src: url("/assets/fonts/AppleSDmGothicNeo-ExtraBold.woff") format("woff"),
    url("/assets/fonts/AppleSDGothicNeo-ExtraBold.ttf") format("truetype"),
    local("AppleSDGothicNeo-ExtraBold");
}

@font-face {
  font-family: "Nanum HimNaeRaNeunMarBoDan";
  font-style: normal;
  font-weight: 600;
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("Nanum HimNaeRaNeunMarBoDan"),
    url("/assets/fonts/Nanum_HimNaeRaNeunMarBoDan.ttf") format("ttf"),
    url("/assets/fonts/Nanum_HimNaeRaNeunMarBoDan.woff") format("woff");
}

/* Apple SD Gothic Neo - Medium (from asset, address ellipsis issue) */
@font-face {
  font-family: "AppleSDGothicNeo-Medium-Asset";
  font-style: normal;
  font-display: swap; /* or block, swap, fallback, optional */
  src: url("/assets/fonts/applesdgothicneom.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneom.woff2") format("woff2"),
    local("AppleSDGothicNeo-Medium");
}

/* Apple SD Gothic Neo - Semi-Bold (from asset, address ellipsis issue) */
@font-face {
  font-family: "AppleSDGothicNeo-SemiBold-Asset";
  font-style: normal;
  font-weight: 600;
  font-display: swap; /* or block, swap, fallback, optional */
  src: url("/assets/fonts/applesdgothicneosb.woff") format("woff"),
    url("/src/assets/fonts/applesdgothicneosb.woff2") format("woff2"),
    local("AppleSDGothicNeo-SemiBold");
}

* {
  -webkit-tap-highlight-color: transparent;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  height: auto;
  background-color: #fafafa;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: none;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  appearance: button;
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  appearance: textfield;
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #525252;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #cccccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mbsc-ios .mbsc-fr-w {
  background-color: #ffffff !important;
}

.mbsc-fr-btn {
  font-family: "AppleSDGothicNeo-Bold";
  font-size: 15px !important;
  font-weight: bold !important;
  color: #525252 !important;
  transition: all 0.2s ease-in-out;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border-color: #e6e6e6 !important;
}

.mbsc-fr-btn:hover {
  color: #525252 !important;
  background-color: #ffffff !important;
}

.mbsc-fr-btn.mbsc-active {
  color: #525252 !important;
  background-color: #ffffff !important;
}

.mbsc-comp > h2 {
  font-family: "AppleSDGothicNeo-Bold";
  font-size: 15px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #525252;
}

.mbsc-comp > p {
  font-family: "AppleSDGothicNeo-Regular";
  font-size: 13px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #525252;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.grecaptcha-badge {
  visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

/* Route */

div.transition-group {
  position: relative;
}
section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
}

body,
#wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.DrawerOverlay,
.DrawerContentContainer {
  z-index: 4 !important;
}

.MainContentContainer {
  position: relative !important;
}

.textLabel {
  margin-bottom: -2px;
}

.eg-flick-viewport {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
  z-index: 4;
  /* height: 1000px; */
}
.eg-flick-public-viewport {
  margin: 0;
  padding: 0;
}

.eg-flick-panel {
  width: 100%;
}

#home-content-panels > div {
  height: 100%;
}

/* Drawer */
.DrawerContainer {
  position: relative;
  z-index: 11;
}

/* Flash */
.flash {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 202;
}

.flash > div > div {
  width: 100%;
  height: 100%;
}

.isHidden {
  display: none;
}

/* App Home Settings */
.appHomeSettingsActive {
  height: 1192px !important;
}

.marquee-container {
  overflow: hidden;
}

/* Header */
/* #main-header-container-public {
  transition: all 0.4s ease;
  transform: translateY(-100%);
} */

.ReactModal__Overlay {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  height: auto;
  inset: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  background: transparent !important;
  border: none !important;
}

.modal-wrapper {
  padding: 24px 36px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
  width: 100%;
  margin: 0 16px;
}

.modal-message {
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.cashback-popup-modal {
  max-width: 341px !important;
  padding: 20px !important;
  > div > div > div > div {
    border-top: none !important;
  }
  padding: 44px 24px 44px 24px !important;
}

.modal-confirm {
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 14px;
  height: 46px;
  color: white;
  background-color: #1dacee;
  outline: none;
  border: transparent;
  border-radius: 4px;
  margin-top: 24px;
  width: 100%;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.displayRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.displayColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.displayWrap {
  flex-wrap: wrap;
}

.alignStart {
  align-items: flex-start;
}

.justifyBetween {
  justify-content: space-between;
}

#modal-root {
  position: fixed;
  top: 0px;
  z-index: 12;
  overscroll-behavior: none;
}

#modal-root > div {
  position: sticky;
  top: 0px;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overscroll-behavior: none;
}

#iframe-slot-machines {
  background: #000000;
  height: 100vh;
}

#iframe-slot-machines::after {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  height: 100vh;
  z-index: -1;
}

#iframe-slot-machines.slots-evoplay {
  background: #000000;
  height: 100%;
}

#iframe-slot-machines.slots-evoplay-portrait-primary iframe {
  height: calc(100vh - 150px);
}

#iframe-slot-machines.slots-evoplay-landscape-primary iframe {
  height: 100vh;
}

#iframe-live-casino {
  background: #000000;
  height: 100%;
}

#iframe-live-casino iframe {
  height: 100%;
}

.demo-page {
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
  position: relative;
}

.control-header-up {
  top: -200px !important;
  transition: top 500ms ease-in-out !important;
}

.control-header-down {
  top: 0px !important;
  transition: top 400ms ease !important;
}

.hidden {
  height: 0;
}

.content-hidden {
  height: 0;
  overflow: hidden;
}

#fullscreen-guide-root {
  position: absolute;
  z-index: 11;
  width: 100vw;
  top: 0;
  overscroll-behavior: none;
}

#chat-bubble-root {
  position: relative;
  z-index: 202;
}

@property --var1 {
  syntax: "<color>";
  inherits: true;
  initial-value: #d9d9d900;
}

@keyframes colorFadeIn {
  0% {
    --var1: #d9d9d900;
  }

  100% {
    --var1: #00000080;
  }
}

@keyframes colorFadeOut {
  0% {
    --var1: #00000080;
  }

  100% {
    --var1: #d9d9d900;
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  overscroll-behavior-x: none;

  overflow: overlay;
  @media screen and (min-width: 842px) {
    min-height: 100vh;
  }
}

.customScroll {
  animation: colorFadeOut 0.4s ease-in-out forwards;

  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 0, 0, 0);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px 6px 6px 6px;
    background: var(--var1);
    border-right: 2px rgba(0, 0, 0, 0) solid;
    border-top: 2px rgba(0, 0, 0, 0) solid;
    border-bottom: 2px rgba(0, 0, 0, 0) solid;
    background-clip: content-box;
  }
  &.fadeInScroll {
    animation: colorFadeIn 0.1s ease-in-out forwards;
  }
}

@media screen and (max-width: 484px) {
  .swiper-container-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    bottom: 0 !important;
  }
}
.swiperCustomBulletActive {
  background: #fff !important;
  opacity: 1 !important;
  box-shadow: inset 0px 0px 10px 2px #fff, 0px 0px 10px 2px #fff;
}
.swiperCustomContainerbullets {
  bottom: 0;
  left: 0;
  width: 100%;
}
.swiperCustomContainerbullets::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  width: 100px;
  background-color: transparent;
  border-width: 15px;
  border-color: transparent transparent #0000005b transparent;
  border-style: solid;
  z-index: -1;
}
.swiperCustomBullet {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: rgb(64, 132, 221);
  border: 0.5px solid #fff;
  opacity: 0.5;
  margin: 0 2px;
  margin-bottom: 1px;
}

.eventsSwiperCustomBullet {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: #016c95;
  border: 0.5px solid #fff;
  opacity: 0.5;
  margin: 0 2px;
}
