@import "src/styles/variables";

.leagueDetailLoader {
  opacity: 0;
  animation: fadeIn 150ms ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.noRounds {
  height: 180px;
  width: 100%;
  background: white;

  .skeleton {
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(8, 75, 132, 0.16);
    height: calc(100% - 32px);
    width: calc(100% - 16px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.gamesTab {
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  column-gap: 6px;
  opacity: 0;
  animation: fadeInOut 2000ms linear infinite;
  .skeleton {
    height: 60px;
    width: 58px;
    border-radius: 12px;
  }

  .greyhoundsSkeleton {
    @extend .skeleton;
    width: 90px;
  }

  .horseSkeleton {
    @extend .skeleton;
    width: 70px;
  }

  @media screen and (min-width: $screen-lg) {
    width: 842px;
  }
  @media screen and (min-width: $screen-xl) {
    width: 1000px;
  }
  @media screen and (min-width: $screen-2xl) {
    width: 1050px;
  }
}

.legueTab {
  opacity: 0;
  height: 38px;
  width: 100%;
  background: white;
  box-shadow: 0px 3px 6px rgba(8, 75, 132, 0.16);
  animation: fadeInOut 2000ms linear infinite;
  .skeleton {
    height: 100%;
    width: 100%;
  }
}

.videoplayer {
  position: absolute;
  opacity: 0;
  min-height: 211px;
  height: 100%;
  width: 100%;
  animation: fadeInOut 2000ms linear infinite;
  .skeleton {
    height: 100%;
    width: 100%;
  }
}

.roundsTab {
  opacity: 0;
  min-height: 40px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  animation: fadeInOut 2000ms linear infinite;
  .resultsContainer {
    display: flex;
    justify-content: center;
    padding: 0px 16px;
    span {
      width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      .resultsButton {
        display: flex;
        height: 16px;
        width: 52px;
        border-radius: 4px;
      }
    }
  }
  .roundsContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;

    @media screen and (min-width: 674px) {
      padding: 0px 16px 0px 35px;
    }

    @media screen and (min-width: 674px) {
      padding: 0px 16px 0px 35px;
    }

    @media screen and (min-width: 842px) {
      padding: 0px 16px;
    }

    @media screen and (min-width: 1024px) {
      padding: 0px 48px;
    }
  }

  .roundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      .roundsButton {
        display: flex;
        height: 14px;
        width: 40px;
      }
    }
  }
}

.betTimer {
  opacity: 0;
  min-height: 50px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInOut 2000ms linear infinite;
  animation-delay: 100ms;
  .skeleton {
    height: 12px;
    width: 120px;
  }
}

.currentMatch {
  opacity: 0;
  height: 40px;
  min-height: 40px;
  width: 100%;
  background: white;
  box-shadow: 0px 3px 6px rgba(8, 75, 132, 0.16);
  animation: fadeInOut 2000ms linear infinite;
  animation-delay: 200ms;
  .skeleton {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
}

.bettingPanel {
  opacity: 0;
  width: 100%;
  height: 240px;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  animation: fadeInOut 2000ms linear infinite;
  animation-delay: 300ms;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  .table {
    width: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px rgba(8, 75, 132, 0.16);
    border-radius: 8px;
    overflow: hidden;
  }

  .header {
    height: 38px;
    width: 100%;
    border-radius: 6px 6px 0px 0px;

    .skeleton {
      height: 100%;
      border-radius: 6px 6px 0px 0px;
    }
  }

  .body {
    width: calc(100% - 16px);
    height: 200px;
    width: 100%;

    .bodyTab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      column-gap: 16px;
      padding-left: 8px;
      .skeleton {
        height: 12px;
        width: 100px;
      }
    }

    .bodyItem {
      align-items: center;
      justify-content: center;
      height: 160px;
      width: cacl(100% -16px);
      padding-left: 8px;
      padding-right: 8px;
      .skeleton {
        height: 140px;
      }
    }
  }
}

.resultsBody {
  .resultsDetail {
    display: flex;
    padding: 0px 20px 24px;
    vertical-align: middle;

    .skeleton {
      height: 100%;
      width: 40px;
      border-radius: 4px;
      margin: 0px 4px;
    }

    .circleSkeleton {
      @extend .skeleton;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      margin: 4px 16px 4px 0px;
    }
  }

  .resultsFrame {
    width: 78.08%;
    padding: 0px 10.96%;
    margin-top: 16px;

    display: flex;
    flex-flow: row wrap;

    .resultsItem {
      height: 72px;
      width: 100%;

      .skeleton {
        margin: 8px;
      }
    }

    @media only screen and (min-width: 508px) {
      .resultsItem {
        height: 72px;
        width: 50%;
      }
    }
  }

  .resultsFooter {
    height: 40px;
    width: 78.08%;
    margin: 65px 10.96% 20px;
    display: flex;

    justify-content: space-between;

    .info {
      width: 48%;
      .skeleton {
        height: 100%;
      }
    }

    .bet365 {
      width: 48%;
      .skeleton {
        height: 100%;
      }
    }
  }
}

.resultsFooter {
  height: 40px;
  width: calc(100% - 30px);
  margin: 16px 15px 20px;
  display: flex;

  justify-content: space-between;

  .info {
    width: 48%;
    .skeleton {
      height: 100%;
      border-radius: 8px;
    }
  }

  .bet365 {
    width: 48%;
    .skeleton {
      height: 100%;
      border-radius: 8px;
    }
  }
}

.details {
  margin: 16px 8px;
  height: 451px;
  border: solid 1px #e6e6e6;
  border-radius: 8px;

  animation: fadeInOut 2000ms linear infinite;

  @media screen and (min-width: 674px) {
    margin: 16px 0px;
  }

  @media screen and (min-width: 842px) {
    margin: 16px;
  }

  .resultsBody {
    padding-top: 24px;
  }

  .detailsHeader {
    width: 100%;
    height: 40px;
    border-radius: 0px;

    .skeleton {
      height: 100%;
      width: 100%;
      border-radius: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .detailsTab {
    height: 49px;
    display: flex;
    padding: 0px 24px;
    column-gap: 20px;
    align-items: center;
    border-bottom: solid 1px #e6e6e6;

    .resultsTab {
      .skeleton {
        width: 94px;
        height: 16px;
      }
    }

    .historyTab {
      .skeleton {
        width: 50px;
        height: 16px;
      }
    }
  }
}

.historyFrame {
  @extend .resultsFrame;
}

.resultsPlayerBet {
  margin: 16px;
  @media screen and (max-width: 674px) {
    margin: 8px 0px;
    border: none;
  }

  height: 170px;
  border: solid 1px #e6e6e6;
  border-radius: 8px;

  animation: fadeInOut 2000ms linear infinite;

  .resultsFrame {
    width: 78.08%;
    padding: 0px 10.96%;

    @media screen and (max-width: 674px) {
      padding: 0;
    }
    margin-top: 16px;

    display: flex;
    flex-flow: row wrap;

    .resultsItem {
      height: 72px;
      width: 100%;

      .skeleton {
        margin: 8px;
      }
    }

    @media only screen and (min-width: 508px) {
      .resultsItem {
        height: 72px;
        width: 50%;
      }
    }
  }
}

.historySkeleton {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.historyPlayerBet {
  padding: 0px 0px 16px 20px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .historyRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }

  .historyRowItem {
    position: relative;
    display: flex;

    .circleSkeleton {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -18px;
    }

    .skeleton {
      height: 100%;
      width: 100%;
    }

    &:last-child {
      justify-content: flex-end;
    }

    &:first-child {
      justify-content: flex-start;
    }
  }
}
