@import "src/styles/variables";

.container {
  display: flex;
  height: 100%;
}

.layoutHeaderProfile {
  position: relative;
  transition: 0.2s ease-in-out all;
  width: 42px;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008fc700;
  cursor: pointer;

  &:active {
    background-color: transparent;
  }
  svg path {
    transition: 0.2s ease-in-out all;
  }
}

.layoutHeaderLinks {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
  &.hasToken {
    margin-right: 24px;
  }
}

.layoutHeaderLink {
  transition: 0.2s ease-in-out all;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 2px 10px 0px;

  font-family: AppleSDGothicNeo-Bold;
  font-size: 14px;
  text-align: left;
  background: transparent;
  color: rgb(255, 255, 255);

  height: 54px;
  display: flex;
  align-items: center;
  background-color: #008fc700;

  &::after {
    position: absolute;
    transition: 0.2s ease-in-out all;
    content: " ";
    left: 0px;
    bottom: 0px;
    margin-left: 4px;
    margin-right: 4px;
    width: calc(100% - 8px);
    height: 6px;
    background: #008fc700;
    border-radius: 9px;
  }
  &.active {
    color: var(--headerActiveColor);
    &::after {
      background: var(--buttonActivebottomLine);
    }
  }

  &:active {
    background-color: transparent;
  }
}

.badge {
  position: absolute;
  right: 2px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f70808;
  color: #ffffff;
  font-family: AppleSDGothicNeo-Bold;
  font-size: 10px;
  line-height: 22px;
  height: 16px;
  width: 16px;
  border-radius: 16px;

  span {
    position: relative;
  }
}

.badge::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0px;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  border: 4px double lighten(#f70808, 20%);
}

.homeLink {
  height: 26px;
  width: 26px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@media (hover: hover) {
  .layoutHeaderLink {
    // &:hover {
    //   background-color: var(--headerHoverColor);
    // }

    // &.active {
    //   &:hover {
    //     background-color: var(--headerHoverColor);
    //   }
    // }

    .homeLink path {
      fill: red;
    }
  }

  .layoutHeaderProfile {
    &:hover {
      svg path {
        fill: var(--headerHoverColor);
      }
      // background-color: #0184bf;
    }
    &::after {
      position: absolute;
      transition: 0.2s ease-in-out all;
      content: " ";
      left: 0px;
      bottom: 0px;
      margin-left: 6px;
      margin-right: 6px;
      width: calc(100% - 12px);
      height: 6px;
      background: #008fc700;
      border-radius: 9px;
    }
    // &.active {
    //   &::after {
    //     background: var(--buttonActivebottomLine);
    //   }

    //   &:hover {
    //     background-color: #008fc700;
    //   }
    // }
  }
}

// @media screen and (min-width: $screen-lg) {
//   &:hover {
//     background-color: #0184bf;
//   }
//   &::after {
//     position: absolute;
//     transition: 0.2s ease-in-out all;
//     content: " ";
//     left: 0px;
//     bottom: 0px;
//     margin-left: 6px;
//     margin-right: 6px;
//     width: calc(100% - 12px);
//     height: 6px;
//     background: #008fc700;
//     border-radius: 9px;
//   }
//   &.active {
//     &::after {
//       background: #0090c7;
//     }

//     &:hover {
//       background-color: #008fc700;
//     }
//   }
// }

@media screen and (min-width: $screen-lg) {
  .layoutHeaderLinks {
    &.hasToken {
      margin-right: 0px;
    }
  }

  .layoutHeaderProfile {
    margin-right: 16px;
  }
}

@media screen and (min-width: 1025px) {
  .layoutHeaderProfile {
    margin-right: 0px;
  }
}
