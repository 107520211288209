.inputBordered {
  position: relative;
  padding: 0px;
  margin: 0px 0px 4px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  transition: all 0.4s ease-in-out;
  height: 56px;
  background: var(--loginInputFieldBG);
}
.inputBorderedError {
  box-shadow: inset 0 0 0 2px var(--inputFieldErrorBorderColor) !important;
  transition: all 0.4s ease-in-out;
}
.inputBorderedActive {
  box-shadow: inset 0 0 0 2px #00acee;
  transition: all 0.4s ease-in-out;
}
.inputBorderedValid {
  box-shadow: inset 0 0 0 1px #00acee !important;
  transition: all 0.4s ease-in-out;
}

.formInput {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 54px;
  color: var(--loginInputColor);
}
.formInput::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  /* padding-bottom: 1px; */
}

.formInputIOS {
  background: transparent;
  border: transparent;
  outline: 0;
  font-size: 16px;
  padding: 0px 0px 0px 16px;
  width: calc(100% - 32px);
  font-family: "AppleSDGothicNeo-SemiBold";
  height: 57px;
  color: var(--loginInputColor);
}

.formInputIOS::placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
  padding-top: 1px;
}

.formInput::-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.formInput:-ms-input-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.formInput:-moz-placeholder {
  color: var(--loginPlaceholderColor);
  font-weight: normal;
  font-size: 14px;
}
.labelContainer {
  position: absolute;
  display: flex;
  top: -8px;
  left: 11px;
  background-color: transparent;
  box-sizing: content-box;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1;
  cursor: default;
  margin: 0px;
  padding: 0px;
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--sidebarItemColor);
  overflow: hidden;
}
.label {
  white-space: nowrap;
  background: var(--loginInputLableBG);
  padding: 0px 5px;
  color: var(--loginInputColor);
}
.labelInfo {
  font-family: AppleSDGothicNeo-SemiBold;
  white-space: nowrap;
  overflow: hidden;
  background: var(--loginInputLableBG);
  max-width: 0px;
  padding-right: 0px;
  transition: all 0.6s ease-in-out 0s;
  color: var(--loginInputColor);
}

.labelInfoActive {
  color: var(--sidebarItemColor) !important;
  max-width: 100%;
  padding-right: 5px;
  transition: all 1s ease-in-out;
}

.labelInfoError {
  color: var(--inputFieldErrorBorderColor);
  max-width: 100%;
  padding-right: 5px;
  transition: all 1s ease-in-out;
}

.confirmMessage {
  position: relative;
  display: flex;
  height: 32px;
  opacity: 0;
  padding-left: 4px;
  overflow: hidden;
  transition: all 0.3s ease-out 0s;
}
.confirmMessageDefault {
  opacity: 1;
  height: 32px;
}
.confirmMessageActive {
  opacity: 1;
  height: 75px;
}
.confirmMessageError {
  opacity: 1;
  height: 75px;
}
.confirmMessageValid {
  opacity: 1;
  height: 75px;
}

.confirmHelper {
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 12px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  opacity: 1;
  height: 0px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  position: absolute;
  row-gap: 4px;
  > span {
    color: var(--loginInputColor);
  }
}

.row {
  display: flex;
  align-items: center;
  column-gap: 4px;

  .icon {
    position: relative;
    top: 1px;
  }
}

.confirmHelperDefault {
  display: flex;
  padding-top: 2px;
  position: relative;
  line-height: 15px;
  font-size: 12px;
  font-family: AppleSDGothicNeo-Medium;
  transition: opacity 0.2s ease-out;
}
.confirmHelperActive {
  height: 71px;
  transition: all 1.5s ease-out;
}
.confirmHelperError {
  height: 71px;
  transition: all 1.5s ease-out;
}

.confirmHelperValid {
  height: 71px;
  transition: all 1.5s ease-out;
}

.confirmHelperText {
  padding-left: 4px;
}
.confirmHelperTextValid {
  padding-left: 4px;
  color: #00acee;
}
.confirmHelperTextError {
  padding-left: 4px;
  color: var(--inputFieldErrorColor);
}

.parenStyle {
  color: rgb(82, 82, 82);
}

.textValid {
  color: #00acee;
}

@media screen and (max-width: 673px) {
  .label {
    background: var(--mobileRegisterInputLableBG1);
  }
  .labelInfo {
    background: var(--mobileRegisterInputLableBG1);
  }
}
